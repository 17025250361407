<template>
  <div class="container-fluid">
    <b-button variant="success" @click="editSceneId = null; currentName = ''; currentButtonsTime = 0; starsCount = 1;" v-b-modal.scene-create-modal>Создать сценарий</b-button>
    <div class="scenes-grid mt-3">
      <div class="scenes-grid__card card" v-for="sceneData in this.scenes" :key="sceneData.id">
        <img class="card-img-top" :src="sceneData.preview_url" alt="Card image cap">
        <div class="card-body">
          <h5 class="card-title text-center">{{ sceneData.scene_name }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">ID: {{ sceneData.id }}</h6>
          <div class="card__actions">
            <b-button variant="success" @click="editSceneId = sceneData.id; currentName = sceneData.scene_name; currentButtonsTime = sceneData.default_buttons_time; starsCount = sceneData.stars_count; loaderText = sceneData.loader_text; btnText = sceneData.start_text" v-b-modal.scene-create-modal>Редактировать</b-button>
            <b-dropdown text="..." class="m-md-2">
              <b-dropdown-item target="_blank" :href="`https://play.xoid.pro/player2?scene_id=${sceneData.id}`">Воспроизвести в плеере</b-dropdown-item>
              <b-dropdown-item @click="editSceneId = sceneData.id; listBackups(sceneData.id)" v-b-modal.backups>Бэкапы</b-dropdown-item>
              <b-dropdown-item href="#" @click="openJsonLinkModal(sceneData.id)">Скопировать ссылку на JSON</b-dropdown-item>
              <b-dropdown-item href="#" @click="copyScene(sceneData.id)">Создать копию</b-dropdown-item>
              <b-dropdown-item href="#" v-b-modal.json-link-modal @click="jsonUrl = `https://play.xoid.pro/player2?scene_id=${sceneData.id}`">Скопировать ссылку на воспроизведение</b-dropdown-item>
              <b-dropdown-item @click="removeScene(sceneData.id)" class="btn-danger">Удалить</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <b-pagination
      v-if="scenesCount > 0"
      v-model="currentPage"
      :total-rows="this.scenesCount"
      :per-page="12"
      aria-controls="videos-table-controls"
      style="justify-content: center !important;"
    ></b-pagination>
    <b-modal
        id="backups"
        title="Бэкапы сценария"
        v-model="backupModal"
        ok-title="Новый"
        cancel-title="Отмена"
        @ok="makeBackup(this.editSceneId)"
        @cancel="backupModal = false;">
      <ul class="list-group">
        <li v-for="backup in this.backups" :key="backup.id" class="list-group-item d-flex justify-content-center align-items-center">
          <span>Бэкап {{ backup.created_time }}</span>
          <div class="ms-auto">
            <button class="btn btn-primary me-1" @click="_restoreScene(backup);"><i class="fa-solid fa-box-open-full"></i></button>
            <button class="btn btn-danger" @click="removeBackup(backup.id)"><i class="fa-solid fa-trash"></i></button>
          </div>
        </li>
      </ul>
    </b-modal>
        <b-modal
      id="scene-create-modal"
      ref="modal"
      v-model="createModal"
      :title="editSceneId === null ? 'Создание сценария' : 'Редактирование сценария'"
      @show="resetModal"
      @hidden="resetModal"
      @ok="changeScene"
    >
      <form ref="form">
        <b-form-group
          label="Название"
          label-for="name-input"
          :state="validationName"
          invalid-feedback="Необходимо ввести название"
        >
          <b-form-input
            id="name-input"
            :state="validationName"
            v-model="currentName"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Стандартное время появления кнопок (от конца, сек.)"
          label-for="buttons-time-input"
          invalid-feedback="Необходимо ввести время"
        >
          <b-form-input
            id="buttons-time-input"
            v-model="currentButtonsTime"
            type="number"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label="Количество звёздочек"
          label-for="stars-input"
          invalid-feedback="Необходимо ввести количество"
        >
          <b-form-input
            id="stars-input"
            v-model="starsCount"
            type="number"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input
            id="button-text-input"
            v-model="btnText"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group>
          <b-form-input
            id="loader-text-input"
            v-model="loaderText"
            type="text"
          ></b-form-input>
        </b-form-group>
        <b-form-group :state="validationPreview" invalid-feedback="Необходимо загрузить превью">
          <input type="file" v-bind="editSceneId === null ? { required: true } : { }" ref="fileUploadForm" accept="image/*" class="custom-file-input form-control" id="fileForm" @change="handleFileChange($event)">
        </b-form-group>
      </form>
    </b-modal>
    <b-modal v-model="jsonModal" id="json-link-modal" ok-only ok-title="ОК" :title="`Ссылка на ${jsonUrl.indexOf('data?scene_id') !== -1 ? 'JSON' : 'воспроизведение'}`" @ok="jsonUrl = ''; jsonModal = false;">
      <a :href="jsonUrl">{{ jsonUrl }}</a>
    </b-modal>
    <b-modal v-model="confirmModal"
             id="confirm-modal"
             ok-title="Да"
             cancel-title="Нет"
             title="Подтверждение"
             @cancel="confirmModal = false;"
             @ok="this.$router.push({ name: 'scene_edit', params: { id: this.editSceneId } })">
      Вы хотите продолжить редактирование сценария?
    </b-modal>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from 'axios';

export default {
  name: "ScenariesGrid",
  computed: {
    ...mapGetters({ 'scenes': 'sceneList', 'scenesCount': 'sceneCount', 'backups': 'backups' }),
    validationPreview() {
      if (this.editSceneId === null)
        return this.currentPreview !== null;
      return true;
    },
    validationName() {
      return !!this.currentName;
    }
  },
  data() {
    return {
      currentPage: 1,
      editSceneId: null,
      currentPreview: null,
      currentName: '',
      jsonUrl: '',
      jsonModal: false,
      confirmModal: false,
      createModal: false,
      backupModal: false,
      currentButtonsTime: 0,
      starsCount: 1,
      loaderText: 'Загружаем сценарий...',
      btnText: 'Начать'
    }
  },
  async created() {
    await this.loadScenes(this.currentPage);
  },
  watch: {
    currentPage(newPage) {
      this.loadScenes(newPage);
    }
  },
  methods: {
    ...mapActions({
      createScene: 'createScene',
      loadScenes: 'loadScenes',
      removeScene: 'removeScene',
      editScene: 'editScene',
      listBackups: 'listBackups',
      restoreScene: 'restoreScene',
      removeBackup: 'removeBackup',
      makeBackup: 'makeBackup',
      copyScene: 'copyScene'
    }),
    resetModal() {
      this.currentName = '';
      this.currentPreview = null;
      this.$refs.fileUploadForm.value = '';
      this.currentButtonsTime = 0;
      this.starsCount = 1;
    },
    handleFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      console.log('Files uploaded', files);
      this.currentPreview = files.item(0);
    },
    openJsonLinkModal(sceneId) {
      axios.get(`/scenes/data-link?scene_id=${sceneId}`).then((response) => {
        this.jsonUrl = response.data.url;
        this.jsonModal = true;
      })
    },
    _restoreScene(backupData) {
       this.restoreScene(backupData.id).then(() => {
         alert(`Сценарий восстановлен с бэкапа ${backupData.created_time}`);
       })
    },
    changeScene() {
      const afterAction = () => {
          this.createModal = false;
          if (this.editSceneId !== null)
            this.confirmModal = true;
      };
      if (this.editSceneId !== null) {
        this.editScene({ sceneId: this.editSceneId, newName: this.currentName, preview: this.currentPreview, defaultButtonsTime: this.currentButtonsTime, starsCount: this.starsCount, loaderText: this.loaderText, btnText: this.btnText }).then(afterAction);
      } else {
        this.createScene({ name: this.currentName, previewFile: this.currentPreview, defaultButtonsTime: this.currentButtonsTime, starsCount: this.starsCount, loaderText: this.loaderText, btnText: this.btnText }).then(afterAction);
      }
    }
  }
}
</script>

<style scoped>
  .scenes-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 30px;
  }

  .card-img-top {
    width: 100%;
    height: 196px;
    object-fit: cover;
  }
  .pagination {
    margin-top: 25px !important;
    }
  @media (max-width: 1620px) {
    .scenes-grid {
      grid-template-columns: repeat(5, 1fr);
    }   
  }
  @media (max-width: 1350px) {
    .scenes-grid {
      grid-template-columns: repeat(4, 1fr);
    }   
  }
  @media (max-width: 1084px) {
    .scenes-grid {
      grid-template-columns: repeat(3, 1fr);
    }   
  }
  @media (max-width: 800px) {
    .scenes-grid {
      grid-template-columns: repeat(2, 1fr);
    }   
  }
</style>
