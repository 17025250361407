<template>
<nav class="navbar navbar-expand-lg navbar-dark bg-success shadow-5-strong mb-3">
  <!-- Container wrapper -->
  <div class="container-fluid">
    <!-- Navbar brand -->
    <a class="navbar-brand" href="#"><img src="/static/img/logo.png" alt="quest logo" height="40"></a>

    <!-- Toggle button -->
    <button
      class="navbar-toggler"
      type="button"
      data-mdb-toggle="collapse"
      data-mdb-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <i class="fas fa-bars"></i>
    </button>

    <!-- Collapsible wrapper -->
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <!-- Left links -->
      <ul v-if="isLoggedIn" class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link active" :to="{ name: 'home' }">Сценарии</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link active" :to="{ name: 'videos' }">Галерея видео</router-link>
        </li>
      </ul>
       <ul v-if="isLoggedIn" class="navbar-nav ml-auto">
        <li v-if="$router.options.history.state.back" class="nav-item">
          <a class="nav-link active" @click="$router.go(-1)">Назад</a>
        </li>
        <li class="nav-item">
          <a class="nav-link active" href="#" @click="logout">Выйти</a>
        </li>
       </ul>
      <ul v-else class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link class="nav-link active" :to="{ name: 'login' }">Войти</router-link>
        </li>
      </ul>
      <!-- Left links -->
    </div>
    <!-- Collapsible wrapper -->
  </div>
    <!-- Container wrapper -->
  </nav>
  <router-view/>
</template>

<script>
  import $ from 'jquery'
  export default {
    computed: {
      isLoggedIn: function() {
        return this.$store.getters.isAuthenticated;
      }
    },
    methods: {
      async logout() {
        await this.$store.dispatch('logout');
        await this.$router.push('/login');
      }
    },
    mounted() {
      $('.simple-typeahead-input').addClass('form-control');
    }
  }
</script>
