<template>
  <div>
    <div class="mb-2 file-upload">
      <input type="file" ref="fileUploadForm" accept="video/*" multiple class="custom-file-input form-control" id="fileForm" @change="handleFileChange($event)">
    </div>
    <b-button variant="success" v-bind="filesToUpload.length <= 0 ? { disabled: true } : { }" v-b-modal.category-modal>Загрузить {{ filesToUpload.length }} файл(а)</b-button>
    <b-button variant="danger" v-bind="filesToUpload.length <= 0 ? { disabled: true } : { }" @click="filesToUpload = []; $refs.fileUploadForm.value = '';">Отмена</b-button>
  </div>
  <b-modal
      id="category-modal"
      ref="modal"
      title="Выбор категории"
      @show="resetModal"
      @hidden="resetModal"
      @ok="startUploadFiles"
    >
      <form ref="form">
        <b-form-group
          label="Категория"
          label-for="category-input"
        >
          <vue3-simple-typeahead ref="typeahead" id="category-input" :items="categories" @selectItem="onSelection" @onInput="selectCategory"></vue3-simple-typeahead>
        </b-form-group>
      </form>
    </b-modal>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
const FILE_CHUNK_SIZE = 1000000; // 1mb

export default {
  name: "ChunkedUploadButton",
  data() {
    return {
      filesToUpload: [],
      category: null
    }
  },
  computed: {
    ...mapGetters({ categories: 'videoCategories' })
  },
  methods: {
    ...mapActions({ uploadFileChunk: 'uploadVideo' }),
    resetModal() {
      this.category = null;
    },
    selectCategory(event) {
      this.category = event.input;
    },
    onSelection(item) {
      this.category = item;
    },
    async startUploadFiles() {
      for (let i = 0; i < this.filesToUpload.length; ++i) {
        let file = this.filesToUpload.item(i);
        let chunksCount = Math.ceil(file.size / FILE_CHUNK_SIZE);
        for (let i = 0; i < chunksCount; ++i) {
          let currentChunk = file.slice(
                  i * FILE_CHUNK_SIZE, Math.min((i + 1) * FILE_CHUNK_SIZE, file.size), file.type
          );
          let fileData = {
            size: file.size,
            chunkOffset: i * FILE_CHUNK_SIZE,
            filename: file.name,
            chunksCount: chunksCount
          }
          this.uploadFileChunk({ chunk: currentChunk, category: this.category, fileData: fileData }).then(() => {}).catch((reason) => { alert(`Ошибка при загрузке файла ${file.name}.\nПричина: ${reason}`) });
        }
      }
    },
    handleFileChange(evt) {
      this.filesToUpload = evt.target.files;
    }
  }
}
</script>

<style scoped>
  .file-upload {
    max-width: 300px;
  }
</style>