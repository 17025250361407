<template>
  <div class="container-fluid d-flex align-items-center videos-container">
      <ChunkedUploadButton />
      <VideosTable class="mt-3 videos-table" />
  </div>
</template>

<script>
import ChunkedUploadButton from "@/components/ChunkedUploadButton.vue";
import VideosTable from "@/components/VideosTable.vue";

export default {
  name: "VideosView",
  components: {
    ChunkedUploadButton,
    VideosTable
  }
}
</script>

<style scoped>
.videos-table {
  flex-basis: 100%;
}
.videos-container {
  flex-flow: row wrap;
}
</style>