import createPersistedState from "vuex-persistedstate";
import { createStore } from 'vuex';

import users from './modules/user';
import videos from './modules/videos';
import scenes from './modules/scenes';

export const store = createStore({
  modules: {
    users,
    videos,
    scenes
  },
  plugins: [createPersistedState()]
})

