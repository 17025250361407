<template>
  <div class="container-fluid d-flex login-container">
      <LoginForm />
  </div>
</template>

<script>
import LoginForm from "@/components/LoginForm.vue";

export default {
  name: "LoginView",
  components: {
    LoginForm
  }
}
</script>

<style scoped>
.login-container{
  align-items: center;
  justify-content: center;
  margin-top: 100px;
}
</style>