import axios from 'axios';

const state = {
    scenes: {},
    backups: [],
    scenesCount: 0
}

const getters = {
    sceneList: state => state.scenes,
    sceneCount: state => state.scenesCount,
    backups: state => state.backups
}

const actions = {
    async loadScenes({ commit }, page) {
        let response = await axios.get('/scenes/list', { params: { page } });
        commit('setScenes', response.data);
    },
    async createScene({ commit }, { name, previewFile, defaultButtonsTime, starsCount, loaderText, btnText }) {
        let form = new FormData();
        form.append("name", name);
        form.append("preview_file", previewFile);
        form.append("default_buttons_time", defaultButtonsTime);
        form.append("stars_count", starsCount);
        form.append('loader_text', loaderText);
        form.append('start_text', btnText);
        let response = await axios.post('/scenes/create', form);
        commit('addScene', response.data);
    },
    async makeBackup({ commit }, sceneId) {
      let response = await axios.post(`/scenes/backup?scene_id=${sceneId}`);
      if (response.status === 200) {
          commit('addBackup', response.data);
      }
    },
    async listBackups({ commit }, sceneId) {
        let response = await axios.get(`/scenes/backups?scene_id=${sceneId}`);
        if (response.status === 200) {
            commit('setBackups', response.data);
        }
    },
    // eslint-disable-next-line
    async restoreScene({ commit }, backupId) {
      await axios.post(`/scenes/restore?backup_id=${backupId}`);

    },
    async removeBackup({ commit }, backupId) {
      let response = await axios.delete(`/scenes/remove?backup_id=${backupId}`);
      if (response.status === 200) commit('removeBackup', backupId);
    },
    async removeScene({ commit }, sceneId) {
        let response = await axios.delete(`/scenes/remove/${sceneId}`);
        if (response.data.success) {
            commit('deleteScene', sceneId);
        }
    },
    async copyScene({ commit }, sceneId) {
      let response = await axios.post(`/scenes/copy?scene_id=${sceneId}`);
      if (response.status === 200) commit('addScene', response.data);
    },
    async editScene({ commit }, { sceneId, newName, defaultButtonsTime, starsCount, loaderText, btnText, preview = null }) {
        let form = new FormData();
        form.append('name', newName);
        if (preview !== null) {
            form.append('preview_file', preview)
        }
        form.append('loader_text', loaderText);
        form.append('start_text', btnText);
        form.append('default_buttons_time', defaultButtonsTime);
        form.append('stars_count', starsCount);
        let response = await axios.post(`/scenes/edit/${sceneId}`, form);
        if (response.data.success) {
            commit('editScene', response.data.scene);
        }
    }
}

const mutations = {
    setScenes(state, scenesData) {
        state.scenes = scenesData.scenes;
        state.scenesCount = scenesData.scenes_count;
    },
    addScene(state, sceneData) {
        state.scenes.push(sceneData);
        state.scenesCount++;
    },
    deleteScene(state, sceneId) {
        state.scenes.forEach(( value, index ) => {
            if (value.id === sceneId) {
                state.scenes.splice(index, 1);
                state.scenesCount--;
            }
        })
    },
    editScene(state, sceneData) {
        state.scenes.forEach(( value, index ) => {
            if (value.id === sceneData.id) {
                state.scenes[index] = sceneData;
            }
        })
    },
    addBackup(state, backupData) {
        state.backups.push(backupData);
    },
    setBackups(state, backups) {
        state.backups = backups;
    },
    removeBackup(state, backupId) {
        state.backups.forEach(( value, index ) => {
            if (value.id === backupId) {
                state.backups.splice(index, 1);
            }
        })
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}