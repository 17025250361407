<template>
    <form @submit.prevent="submitForm" class="login-form">
      <div class="mb-3">
        <label for="username" class="form-label">Имя пользователя:</label>
        <input type="text" id="username" name="username" v-model="userData.username" class="form-control" />
      </div>
      <div class="mb-3">
        <label for="password" class="form-label">Пароль:</label>
        <input type="password" id="password" name="password" v-model="userData.password" class="form-control" />
      </div>
      <button type="submit" class="btn btn-success">Войти</button>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: "LoginForm",
  data() {
    return {
      userData: {
        username: '',
        password: ''
      }
    }
  },
  computed: {
    ...mapGetters({ isAuthenticated: 'isAuthenticated' }),
  },
  methods: {
    ...mapActions(['login']),
    async submitForm() {
      await this.login(this.userData);
      if (!this.isAuthenticated) {
        alert('Некорректные данные для входа.');
      } else {
        await this.$router.push('/');
      }
    }
  }
}
</script>
<style scoped>
  .login-form {
    min-width: 500px;
  }
</style>
