<template>
  <div>
    <FoldersDropdown v-model="currentCategory" :show-current="true" />
    <b-table striped :items="this.videos" :fields="videoFields" :current-page="currentPage" :per-page="5" responsive="sm">
      <template #thead-top>
        <tr class="sortable-header">
          <th v-for="field in videoFields" :key="field.key">
            <span v-if="field.sortable" class="theader" href="#" @click="changeSorting(field.key)" :aria-sort="sortBy === field.key ? sortDir : 'none'">
                {{ field.label }}
                <span aria-hidden="true"></span>
            </span>
            <span v-else>{{ field.label }}</span>
          </th>
        </tr>
      </template>
      <template #cell(name)="data">
        {{ data.item.isUploading ? `${data.item.name} (${data.item.progress}%, загружается)` : data.value }}
      </template>
      <template #cell(size)="data">
        {{ formatFileSize(data.value) }}
      </template>
      <template #cell(length)="data">
        {{ formatLength(data.value) }}
      </template>
      <template #cell(scenes_used)="data">
        {{ data.value.join(', ') }}
      </template>
      <template #cell(preview_url)="data">
        <img v-if="!data.isUploading" :src="data.value" :alt="data.item.name" width="150">
        <div v-else>Загружается...</div>
      </template>
      <template #cell(actions)="data">
          <b-dropdown v-if="!data.isUploading" id="actions-dropdown" text="Действия" class="m-md-2">
            <b-dropdown-item target="_blank" :href="data.item.video_url">Просмотр</b-dropdown-item>
            <b-dropdown-item @click="editVideoId = data.item.id; newName = data.item.name; currentPreview = null; newCategory = data.item.category; this.$refs.typeahead.input = newCategory === null ? '' : newCategory;" v-b-modal.edit-modal>Редактировать</b-dropdown-item>
            <b-dropdown-item @click="deleteVideo(data.item.id, data.item.scenes_used)" class="btn-danger">Удалить</b-dropdown-item>
          </b-dropdown>
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="videosCount"
      :per-page="5"
      aria-controls="videos-table-controls"
      style="justify-content: center !important;"
    ></b-pagination>
    <b-modal
      id="edit-modal"
      ref="modal"
      title="Редактирование"
      @show="resetModal"
      @hidden="resetModal"
      @ok="changeVideo"
    >
      <form ref="form">
        <b-form-group
          label="Название"
          label-for="name-input"
          invalid-feedback="Необходимо ввести название"
        >
          <b-form-input
            id="name-input"
            v-model="newName"
            required
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Категория" label-for="category-input">
          <vue3-simple-typeahead ref="typeahead" :defaultItem="newCategory" id="category-input" :items="categories" @selectItem="onSelection" @onInput="onInput"></vue3-simple-typeahead>
        </b-form-group>
        <b-form-group>
          <input type="file" ref="fileUploadForm" accept="image/*" class="custom-file-input form-control" id="fileForm" @change="_handleFileChange($event)">
        </b-form-group>
      </form>
    </b-modal>
  </div>

</template>

<script>
import {mapGetters, mapActions} from "vuex";
import FoldersDropdown from "@/components/FoldersDropdown.vue";

export default {
  name: "VideosTable",
  computed: {
    ...mapGetters({ videos: 'videosList', videosCount: 'videosCount', categories: 'videoCategories' }),
  },
  components: {FoldersDropdown},
  watch: {
    currentPage(newPage) {
      this.loadVideos(newPage);
    },
    currentCategory(newCategory) {
      this.currentPage = 1;
      this.loadVideos(this.currentPage);
      this.loadVideosCount(newCategory);
    }
  },
  data() {
    return {
      editVideoId: null,
      newName: '',
      newCategory: '',
      currentPage: 1,
      sortBy: 'id',
      currentCategory: null,
      sortDir: 'descending',
      currentPreview: null,
      videoFields: [
        {
          key: 'id',
          label: 'ID',
          sortable: true
        },
        {
          key: 'name',
          label: 'Название',
          sortable: true
        },
        {
          key: 'preview_url',
          label: 'Предпросмотр',
          sortable: false
        },
        {
          key: 'size',
          label: 'Размер файла',
          sortable: false
        },
        {
          key: 'length',
          label: 'Длина видео',
          sortable: false
        },
        {
          key: 'scenes_used',
          label: 'Сцены',
          sortable: false
        },
        {
          key: 'created_time',
          label: 'Дата создания',
          sortable: true
        },
        {
          key: 'actions',
          label: ''
        }
      ]
    }
  },
  async created() {
    await this.loadVideosCount();
    await this.loadVideos(this.currentPage);
  },
  methods: {
    ...mapActions({ loadVideosServer: 'loadVideos', loadVideosCount: 'loadVideosCount', removeVideo: 'deleteVideo', editVideo: 'editVideo' }),
    deleteVideo: function(videoId, scenesUsed) {
      if (scenesUsed.length > 0)
        alert('Нельзя удалить видео, когда оно используется в сцене(ах)');
      else
        return this.removeVideo(videoId);
    },
    onInput(event) {
      this.newCategory = event.input;
    },
    onSelection(item) {
      this.newCategory = item;
    },
    async loadVideos(page) {
      await this.loadVideosServer({ page,  sort_by: this.sortBy, sort_dir: this.sortDir, category: this.currentCategory });
    },
    resetModal() {
      this.editVideoId = null;
      this.newName = '';
      this.currentPreview = null;
      this.newCategory = null;
      this.$refs.typeahead.input = '';
    },
    changeVideo() {
      this.editVideo({videoId: this.editVideoId, newName: this.newName, newPreview: this.currentPreview, newCategory: this.newCategory});
    },
    changeSorting(key) {
      if (this.sortBy === key) {
        this.sortDir = this.sortDir === 'ascending' ? 'descending' : 'ascending';
      } else {
        this.sortBy = key;
        this.sortDir = 'ascending';
      }
      this.loadVideos(this.currentPage);
    },
    formatFileSize(bytes) {
          if (bytes === 0) return '0 байт';
          const k = 1024;
          const dm = 2;
          const sizes = ['байт', 'Кб', 'Мб', 'Гб', 'Тб', 'Пб', 'Эб', 'Зб', 'Йб'];

          const i = Math.floor(Math.log(bytes) / Math.log(k));

          return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    formatLength(seconds) {
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        const s = Math.round(seconds % 60);
        return [
          h,
          m > 9 ? m : (h ? '0' + m : m || '0'),
          s > 9 ? s : '0' + s
        ].filter(Boolean).join(':');
    },
    _handleFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length)
        return;
      this.currentPreview = files.item(0);
    }
  }
}
</script>

<style scoped>
.theader[aria-sort="descending"] span::after {
  content: "▼";
  color: currentColor;
  font-size: 100%;
  top: 0;
}

.theader[aria-sort="ascending"] span::after {
  content: "▲";
  color: currentColor;
  font-size: 100%;
  top: 0;
}
.theader {
  cursor: pointer;
}
</style>

<style>
    thead tr:not(.sortable-header) {
      display: none;
    }
</style>