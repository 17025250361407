<template>
  <div class="dropdown_folders">
    <b-dropdown id="folder_list" text="Выбрать папку" class="folder_list-drop">
      <b-dropdown-item @click="changeCategory(null)">Всё</b-dropdown-item>
      <b-dropdown-item v-for="category in categories" :key="category" @click="changeCategory(category)">{{ category }}</b-dropdown-item>
    </b-dropdown>
    <span v-if="showCurrent" class="current-filter">{{ this.modelValue === null ? 'Всё' : this.modelValue }}</span>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "FoldersDropdown",
  props: {
    modelValue: [String, Object],
    showCurrent: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapGetters({categories: 'videoCategories'}),
  },
  methods: {
    changeCategory(category) {
      this.$emit('update:modelValue', category);
    }
  }
}
</script>
