import $ from 'jquery';
import $cArrows from "@/assets/js/arrows";
import 'jquery-ui/ui/widgets/draggable';

var options = {render: {strokeStyle: '#D58333', lineWidth: 2}, arrow: {connectionType: 'rectangleAuto'}};
var arrowsDrawer;
const HistoryLimit = 15;
const debug = true;

class HistoryElem {
    constructor() {
        this.Type = "";
        this.Element = null;
    }
}

class Node {
    constructor() {
        this.Id = "";
        this.Name = "";
        this.Video = "";
        this.Preview = "";
        this.Length = 0;
        this.Tail_Time = 0;
        this.Buttons_Time = 0;
        this.Summary = "";
        this.Position = {
            X: 0,
            Y: 0,
        }
        this.Childs = [];
        this.ChildsUrl = [];
    }

    ToJSON() {
        let Entity = {
            Name: this.Name,
            Video: this.Video,
            Preview: this.Preview,
            Title: this.Title,
            Summary: this.Summary,
            Position: this.Position,
            Childs: this.Childs,
            Length: this.Length,
            Tail_Time: this.Tail_Time,
            Buttons_Time: this.Buttons_Time
        }
        return JSON.stringify(Entity);
    }
}

class ButtonNode {
    constructor() {

        this.Id = "";
        this.Title = "";
        this.IsUrl = false;
        this.Value = "";
        this.FromId = "";
        this.ToId = "";
        this.Position = {
            X: 0,
            Y: 0,
        }
    }
}

var CtrlZArray = [];
var RemovedNodes = [];
var RemovedButtonNodes = [];
var ButtonNodeArray = [];
var NodeArray = [];
var ActiveNodeType;
var ActiveNodeId;
var FirstRelative = true;
var SetModalOpen = false;
var FirstRelativeId;
var changeIntroActive = false;


let scale = 1.0;
export const SetScale = (_scale) => scale = _scale;

export function GetButtonNodeByIds(fromId, toId) {
    var entity = {};
    ButtonNodeArray.forEach(element => {
        if (element.FromId === fromId && element.ToId === toId) {
            Object.assign(entity, element);
        }
    });
    return entity;
}

export function GetButtonNodeByIds2(fromId, toId, Copied) {
    var entity = {};
    ButtonNodeArray.forEach(element => {
        if (element.FromId === fromId && element.ToId === toId) {
            if (Copied) {
                Object.assign(entity, element);
            } else {
                entity = element;
            }

        }
    });
    return entity;
}

export function GetNodeById(id) {
    var entity = {};
    NodeArray.forEach(element => {
        if (element.Id === id) {
            Object.assign(entity, element);
        }
    });
    return entity;
}

let arrowsRelativesData = [];
const draggableBindings = {
    containment: "#workzone",
    scroll: true,
    start: (event) => {
        const currentArrowsData = arrowsDrawer.CanvasStorage[2];
        arrowsRelativesData = currentArrowsData.filter(() => true); // Make a copy, do not ref the object.
        let idsList = [];
        $('.active_node').each(function (key, value) {
            idsList.push(`#${$(value).attr('id')}`)
        });
        if (idsList.length === 1) idsList.pop();
        idsList.push(`#${event.target.id}`);
        for (let index = currentArrowsData.length - 1; index >= 0; index--) {
            if (idsList.includes(currentArrowsData[index][0]) || idsList.includes(currentArrowsData[index][1])) {
                // This is a parent or a child of the current draggable node. Remove the
                // arrow till the drag stops.
                currentArrowsData.splice(index, 1);
            }
        }
        arrowsDrawer.redraw();
    },
    stop: (event) => {
        // Insert back the arrows and redraw them.
        arrowsDrawer.CanvasStorage[2] = arrowsRelativesData;
        arrowsDrawer.redraw();
        let el = $(event.target);
        el.data('left', parseFloat(el.css('left')) / scale);
        el.data('top', parseFloat(el.css('top')) / scale);
    },
    drag: function (event, ui) {
        var changeLeft = ui.position.left - ui.originalPosition.left; // find change in left
        var newLeft = ui.originalPosition.left + changeLeft; // adjust new left by our zoomScale

        var changeTop = ui.position.top - ui.originalPosition.top; // find change in top
        var newTop = ui.originalPosition.top + changeTop; // adjust new top by our zoomScale


        ui.position.left = newLeft;
        ui.position.top = newTop;
    }
}

$.fn.multiDraggable = function (opts) {
    var initLeftOffset = [],
        initTopOffset = [];
    
    return this.each(function () {        
        $(this).draggable(opts, {
            start: function (event) {
                draggableBindings.start(event);
                if (!$(this).hasClass('active_node')) return;
                $(".node_sc, .node_link").draggable("option", "scroll", false);

                var pos = $(this).position();
                //lastPosLeft = pos.left;
                //lastPosTop = pos.top;
                $(opts.group).each(function (key, value) {
                    var elemPosleft = parseFloat($(value).css('left'));
                    var elemPostop = parseFloat($(value).css('top'));
                    initLeftOffset[key] = elemPosleft - pos.left;
                    initTopOffset[key] = elemPostop - pos.top;
                });
                opts.startNative ? opts.startNative() : {};
            },
            drag: function (event, ui) {
                draggableBindings.drag(event, ui);
                if (!$(this).hasClass('active_node')) return;
                var pos = $(this).position();

                $(opts.group).each(function (key, value) {

                    var x = pos.left + initLeftOffset[key];
                    var y = pos.top + initTopOffset[key];
                    $(value).css({
                        left: x,
                        top: y
                    });

                });
                opts.dragNative ? opts.dragNative() : {};           
            },
            stop: function (event) {
                draggableBindings.stop(event);
                if (!$(this).hasClass('active_node')) return;
                $(".node_sc, .node_link").draggable("option", "scroll", true);
                var pos = $(this).position();

                $(opts.group).each(function (key, value) {
                    var x = (pos.left + initLeftOffset[key]);
                    var y = (pos.top + initTopOffset[key]);
                    var id = $(value).attr('id');
                    NodeArray.forEach(element => {
                        if (id == element.Id) {
                            element.Position.X = x;
                            element.Position.Y = y;
                        }
                    });
                    ButtonNodeArray.forEach(element => {
                        if (id == element.Id) {
                            element.Position.X = x;
                            element.Position.Y = y;
                        }
                    });
                    $(value).data('left', parseFloat($(value).css('left')) / scale);
                    $(value).data('top', parseFloat($(value).css('top')) / scale);
                });
                opts.stopNative ? opts.stopNative() : {};
            },
        });
    });
};

export function GetLog() {
    if (debug) {
        console.log("NodeArray");
        console.log(NodeArray);
        console.log("ButtonNodeArray");
        console.log(ButtonNodeArray);
        console.log("RemovedNodes");
        console.log(RemovedNodes);
        console.log("RemovedButtonNodes");
        console.log(RemovedButtonNodes);
        console.log("CtrlZArray");
        console.log(CtrlZArray);
    }

}

export function CtrlZMethod() {
    if (CtrlZArray.length == 0) return;
    var LastCtrlZElem = CtrlZArray.pop();
    var Node, NodeId, NodeInfo;
    switch (LastCtrlZElem.Type) {
        case "NODE_MODIFIED":
            Node = LastCtrlZElem.Element;
            NodeArray.forEach(element => {
                if (element.Id == Node.Id) {
                    Object.assign(element, Node);
                    $(`#${element.Id} .node_title_sc`).text(element.Name);
                }
            });
            break;
        case "NODE_DELETED":
            NodeInfo = LastCtrlZElem.Element;
            Node = NodeInfo.Node;
            NodeArray.push(NodeInfo.Node);
            // Добавление ноды
            $("#workzone").append(`<div class="node_sc" id="${NodeInfo.Node.Id}">
    <div class="node_settings" id="node_settings_${NodeInfo.Node.Id}"><span class="node_settings_elem" OnClick='EditNode("node_sc_${NodeInfo.Node.Id.substring(8)}","scene_node"),ModalOn(),ShowSettings("${NodeInfo.Node.Id}")')'>Настроить сцену</span><span class="node_settings_elem" onclick="DeleteNode()">Удалить сцену</span></div>
    <span class="node_title_sc" title="${NodeInfo.Node.Name}">${NodeInfo.Node.Name}</span>
    <span class="node_edit_sc" OnClick='ShowSettings("${NodeInfo.Node.Id}"), EditNode("${NodeInfo.Node.Id}","scene_node")'>...</span>
    <span class="relativ_sc"><img class="node_background_sc" src="${NodeInfo.Node.Preview}" alt="+"></span>
    <span class="node_icon_relativ_sc" OnClick='AddRelative("node_sc_${NodeInfo.Node.Id.substring(8)}", false)'><img class="node_relativ_icon_sc" src="/static/img/icon-plus.png" alt="+"></span>
    </div>`);
            $(".node_sc").draggable(draggableBindings);
            $(".node_sc").mouseup(function () {
                var clickedID = $(this).attr('id');
                NodeArray.forEach(NodeArrayElem => {
                    if (NodeArrayElem.Id == clickedID) {
                        NodeArrayElem.Position.X = $(this).offset().left;
                        NodeArrayElem.Position.Y = $(this).offset().top;
                    }
                });

            });
            $(`#${NodeInfo.Node.Id}`).offset({left: NodeInfo.Node.Position.X, top: NodeInfo.Node.Position.Y});
            // Добавление чилдов ноды
            NodeInfo.Node.Childs.forEach(element => {
                ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                    return f.Id !== `jump_node_${NodeInfo.Node.Id}_${element}`
                });

                ButtonNodeArray.push(new ButtonNode());

                ButtonNodeArray[ButtonNodeArray.length - 1].Id = `jump_node_${NodeInfo.Node.Id}_${element}`;
                ButtonNodeArray[ButtonNodeArray.length - 1].Title = `Переход на сцену`;
                ButtonNodeArray[ButtonNodeArray.length - 1].IsUrl = false;
                ButtonNodeArray[ButtonNodeArray.length - 1].FromId = NodeInfo.Node.Id;
                ButtonNodeArray[ButtonNodeArray.length - 1].ToId = element;

                ButtonNodeArray[ButtonNodeArray.length - 1].Position = {
                    X: 100, //+ (newnodepadding * (NodeArray.length + ButtonNodeArray.length)),
                    Y: 100
                }

                arrowsDrawer.arrow(`#${NodeInfo.Node.Id}`, `#${element}`);
                arrowsDrawer.redraw();
                FirstRelative = true;
                $(`.node_icon_relativ_link`).hide();
                $(`.node_icon_relativ_sc`).show();
            });

            NodeInfo.Node.ChildsUrl.forEach(element => {
                arrowsDrawer.remove(`#${NodeInfo.Node.Id}`, `#${element}`);
                arrowsDrawer.arrow(`#${NodeInfo.Node.Id}`, `#${element}`);
            });
            // Добавление связей от родительских нод

            NodeInfo.buttonNodes.forEach(element => {
                NodeArray.forEach(element2 => {
                    if (element2.Id == element.FromId) {
                        ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                            return f.Id !== element.Id
                        });
                        ButtonNodeArray.push(new ButtonNode());
                        ButtonNodeArray[ButtonNodeArray.length - 1].Id = element.Id;
                        ButtonNodeArray[ButtonNodeArray.length - 1].Title = element.Title;
                        ButtonNodeArray[ButtonNodeArray.length - 1].IsUrl = false;
                        ButtonNodeArray[ButtonNodeArray.length - 1].FromId = element.FromId
                        ButtonNodeArray[ButtonNodeArray.length - 1].ToId = element.ToId;
                        ButtonNodeArray[ButtonNodeArray.length - 1].Position = element.Position;

                        element2.Childs.push(element.ToId);

                        arrowsDrawer.arrow(`#${element.FromId}`, `#${NodeInfo.Node.Id}`);
                        arrowsDrawer.redraw();
                        FirstRelative = true;
                        $(`.node_icon_relativ_link`).hide();
                        $(`.node_icon_relativ_sc`).show();
                    }
                });
            });

            RemovedNodes = RemovedNodes.filter(function (f) {
                return f !== NodeInfo.Node.Id
            });

            break;
        case "NODE_CREATED":
            NodeId = LastCtrlZElem.Element;
            NodeArray.forEach(element => {
                if (element.Id == NodeId) {
                    NodeArray = NodeArray.filter(function (f) {
                        return f !== element
                    })
                }
            });
            $(`#${NodeId}`).remove();
            break;
        case "BUTTON_NODE_MODIFIED":
            Node = LastCtrlZElem.Element;
            ButtonNodeArray.forEach(element => {
                if (element.Id == Node.Id) {
                    Object.assign(element, Node);
                    if (element.IsUrl) {
                        $(`#${element.Id} input`).val(element.Url);
                    } else {
                        $(`#${element.Id} input`).val(element.Title);
                    }
                }
            });
            break;
        case "BUTTON_NODE_DELETED":
            NodeInfo = LastCtrlZElem.Element;
            ButtonNodeArray.push(NodeInfo.Node);
            if (NodeInfo.IsUrl) {
                $("#workzone").append(`<div class="node_link" id="jump_node_${NodeInfo.Node.Id.substring(10)}">
    <div class="node_settings" id="node_settings_j${NodeInfo.Node.Id.substring(10)}"><span class="node_settings_elem" OnClick='ModalOn(),ShowSettings("j${NodeInfo.Node.Id.substring(10)}")')'>Настроить переход</span><span class="node_settings_elem" onclick="DeleteNode()">Удалить переход</span></div>
    <span class="node_title_link">Переход на ссылку</span>
    <span class="node_edit_link" OnClick='ShowSettings("j${NodeInfo.Node.Id.substring(10)}"),EditNode("jump_node_${NodeInfo.Node.Id.substring(10)}", "url_node")'>...</span>
    <input type="text" OnInput='EditNode("jump_node_${NodeInfo.Node.Id.substring(10)}", "url_node"),ChangeText("jump_input_${NodeInfo.Node.Id.substring(10)}",true),SaveChanges()' placeholder="Название ссылки" class="node_input_link blle" id="jump_input_${NodeInfo.Node.Id.substring(10)}" value="${NodeInfo.Node.Url}">
    
    <span class="node_icon_relativ_link" OnClick='AddRelative("jump_node_${NodeInfo.Node.Id.substring(10)}", true)'><img class="node_relativ_icon_link" src="/static/img/icon-plus.png" alt="+"></span>
</div>`);
                $(".node_link").draggable(draggableBindings);
                $(`.node_icon_relativ_link`).hide();
                $(`#${NodeInfo.Node.Id}`).offset({left: NodeInfo.Node.Position.X, top: NodeInfo.Node.Position.Y});

                NodeInfo.ParentsId.forEach(element => {
                    arrowsDrawer.arrow(`#${element}`, `#${NodeInfo.Node.Id}`);
                    NodeArray.forEach(element2 => {
                        if (element2.Id == element)
                            element2.ChildsUrl.push(NodeInfo.Node.Id);
                    });
                });

            } else {
                arrowsDrawer.arrow(`#${NodeInfo.Node.FromId}`, `#${NodeInfo.Node.ToId}`);
            }
            RemovedButtonNodes = RemovedButtonNodes.filter(function (f) {
                return f !== NodeInfo.Node.Id
            });
            break;
        case "BUTTON_NODE_CREATED":
            NodeId = LastCtrlZElem.Element;
            ButtonNodeArray.forEach(element => {
                if (element.Id == NodeId) {
                    ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                        return f !== element
                    });
                }
            });
            $(`#${NodeId}`).remove();
            break;
        case "ADD_RELATIVE":
            NodeInfo = LastCtrlZElem.Element;

            if (NodeInfo.IsUrl) {
                arrowsDrawer.remove(`#${NodeInfo.FromId}`, `#${NodeInfo.ToId}`);
                NodeArray.forEach(element => {
                    if (element.Id == NodeInfo.FromId) {
                        element.ChildsUrl = element.ChildsUrl.filter(function (f) {
                            return f !== NodeInfo.ToId
                        });
                    }
                });
            } else {
                arrowsDrawer.remove(`#${NodeInfo.FromId}`, `#${NodeInfo.ToId}`);
                NodeArray.forEach(element => {
                    if (element.Id == NodeInfo.FromId) {
                        element.Childs = element.Childs.filter(function (f) {
                            return f !== NodeInfo.ToId
                        });
                        ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                            return f.Id !== NodeInfo.Id
                        });
                    }
                });
            }
            break;
        case "REMOVE_RELATIVE":
            NodeInfo = LastCtrlZElem.Element;
            if (NodeInfo.IsUrl) {
                arrowsDrawer.arrow(`#${NodeInfo.FromId}`, `#${NodeInfo.ToId}`);
                NodeArray.forEach(element => {
                    if (element.Id == NodeInfo.FromId) {
                        element.ChildsUrl.push(NodeInfo.ToId);
                    }
                });
            } else {
                ButtonNodeArray.push(NodeInfo.buttonNode);
                arrowsDrawer.arrow(`#${NodeInfo.FromId}`, `#${NodeInfo.ToId}`);
                NodeArray.forEach(element => {
                    if (element.Id == NodeInfo.FromId) {
                        element.Childs.push(NodeInfo.ToId);
                        NodeInfo = LastCtrlZElem.Element;
                    }
                });
            }
            break;

        default:
            break;
    }
    arrowsDrawer.redraw();
}

export function CtrlZCheckLimit() {
    if (CtrlZArray.length >= HistoryLimit) CtrlZArray.shift();
}

export function SaveSchema() {
    let handleNode = (element) => {
        let el = $(`#${element.Id}`);
        element.Position.X = parseFloat(el.data('left')) + $('.left').width();
        element.Position.Y = parseFloat(el.data('top')) + 82;
    };
    NodeArray.forEach(handleNode);
    ButtonNodeArray.forEach(handleNode);
    let Schema = {};
    Schema["NodeArray"] = NodeArray;
    Schema["ButtonNodeArray"] = ButtonNodeArray;
    Schema["RemovedButtonNodes"] = RemovedButtonNodes;
    Schema["RemovedNodes"] = RemovedNodes;

    let text = JSON.stringify(Schema);

    return text;
}


export function ChangeIntro() {
    $(`#${ActiveNodeId}`).addClass("node_disabled");
    changeIntroActive = true;
    SetModalOff();
}

export function LoadSchema(json) {
    ClearGlobalState();
    let Schema = json;
    if (Schema && Object.keys(Schema).length === 0) return;
    NodeArray = Schema["NodeArray"];
    ButtonNodeArray = Schema["ButtonNodeArray"];
    RemovedButtonNodes = Schema["RemovedButtonNodes"];
    RemovedNodes = Schema["RemovedNodes"];

    let offsetToMove = 0;

    NodeArray.forEach(element => {
        let xPos = (element.Position.X - $('.left').width());
        if (xPos < 0 && -xPos > offsetToMove) {
           offsetToMove = -xPos;
        }
        $(`#${element.Id}`).remove();
    });
    ButtonNodeArray.forEach(element => {
        $(`#${element.Id}`).remove();
    });

    arrowsDrawer.clear();
    //$(".workzone").empty();


    NodeArray.forEach(element => {
        $("#workzone").append(`<div class="node_sc" id="${element.Id}">
    <div class="node_settings" id="node_settings_${element.Id}"><span class="node_settings_elem" OnClick='EditNode("node_sc_${element.Id.substring(8)}","scene_node"),ModalOn(),ShowSettings("${element.Id}")')'>Настроить сцену</span><span class="node_settings_elem" onclick="DeleteNode()">Удалить сцену</span> <span class="node_settings_elem" onclick="ChangeIntro()">Заменить видео</span></div>
    <span class="node_title_sc" title="${element.Name}">${element.Name}</span>
    <span class="node_edit_sc" OnClick='ShowSettings("${element.Id}"), EditNode("node_sc_${element.Id.substring(8)}","scene_node")'>...</span>
    <span class="relativ_sc"><img class="node_background_sc" src="${element.Preview}" alt="+"></span>
    <span class="node_icon_relativ_sc" OnClick='AddRelative("node_sc_${element.Id.substring(8)}", false)'><img class="node_relativ_icon_sc" src="/static/img/icon-plus.png" alt="+"></span>
    </div>`);
        $(".node_sc").draggable(draggableBindings);
        $(".node_sc").mouseup(function () {
            var clickedID = $(this).attr('id');
            NodeArray.forEach(NodeArrayElem => {
                if (NodeArrayElem.Id == clickedID) {
                    NodeArrayElem.Position.X = $(this).offset().left;
                    NodeArrayElem.Position.Y = $(this).offset().top;
                }
            });

        });
        $(`#${element.Id}`).offset({left: offsetToMove === null ? element.Position.X : element.Position.X + offsetToMove, top: element.Position.Y});
    });

    ButtonNodeArray.forEach(element => {
        if (element.IsUrl) {
            $("#workzone").append(`<div class="node_link" id="${element.Id}">
    <div class="node_settings" id="node_settings_j${element.Id.substring(10)}"><span class="node_settings_elem" OnClick='ModalOn(),ShowSettings("j${element.Id.substring(10)}")')'>Настроить переход</span><span class="node_settings_elem" onclick="DeleteNode()">Удалить переход</span></div>
    <span class="node_title_link">${element.Title}</span>
    <span class="node_edit_link" OnClick='ShowSettings("j${element.Id.substring(10)}"),EditNode("jump_node_${element.Id.substring(10)}", "url_node")'>...</span>
    <input type="text" value="${element.Url}" OnInput='EditNode("jump_node_${element.Id.substring(10)}", "url_node"),ChangeText("jump_input_${element.Id.substring(10)}",true),SaveChanges()' placeholder="Название ссылки" class="node_input_link blle" id="jump_input_${element.Id.substring(10)}">
    
    <span class="node_icon_relativ_link" OnClick='AddRelative("jump_node_${element.Id.substring(10)}", true)'><img class="node_relativ_icon_link" src="/static/img/icon-plus.png" alt="+"></span>
</div>`);
            $(".node_link").draggable(draggableBindings);
            $(`.node_icon_relativ_link`).hide();
            $(`#${element.Id}`).offset({left: offsetToMove === null ? element.Position.X : element.Position.X + offsetToMove, top: element.Position.Y});
        }
    });

    NodeArray.forEach(element => {
        element.ChildsUrl.forEach(element2 => {
            arrowsDrawer.arrow(`#${element.Id}`, `#${element2}`);
        });
        element.Childs.forEach(element2 => {
            arrowsDrawer.arrow(`#${element.Id}`, `#${element2}`);
        });
    });

    arrowsDrawer.redraw();
}

export function FindButtonNode(button_id, target_obj) {
    ButtonNodeArray.forEach(element => {
        if (element.Id == button_id) {
            Object.assign(target_obj, element);
        }

    });

}

export function SaveChanges() {

    switch (ActiveNodeType) {
        case "scene_node":
            NodeArray.forEach(element => {
                if (element.Id == ActiveNodeId) {
                    //Привязка ctrlz
                    let CtrlZElem = new HistoryElem();
                    CtrlZElem.Element = {};
                    CtrlZCheckLimit();
                    CtrlZElem.Type = "NODE_MODIFIED";
                    Object.assign(CtrlZElem.Element, element);
                    CtrlZArray.push(CtrlZElem);

                    element.Childs.forEach(element2 => {
                        ButtonNodeArray.forEach(element3 => {
                            if (element3.FromId == element.Id && element3.ToId == element2) {
                                element3.Title = $(`#rel_id_${element2}`).val();
                            }
                        });
                    });

                    element.Name = $('#node_info_name').val();
                    element.Tail_Time = parseFloat($('#node_info_tailtime').val());
                    element.Buttons_Time = parseFloat($('#node_info_buttonstime').val());
                    $(`#${ActiveNodeId} .node_title_sc`).text(element.Name);

                }
            });

            break;
        case "jump_node":
            ButtonNodeArray.forEach(element => {
                if (element.Id == ActiveNodeId) {
                    //Привязка ctrlz
                    let CtrlZElem = new HistoryElem();
                    CtrlZElem.Element = {};
                    CtrlZCheckLimit();
                    CtrlZElem.Type = "BUTTON_NODE_MODIFIED";
                    Object.assign(CtrlZElem.Element, element);
                    CtrlZArray.push(CtrlZElem);

                    element.Title = $('#node_info_jumptitle').val();
                    $(`#${ActiveNodeId} input`).val(element.Title);
                }
            });

            break;
        case "url_node":
            ButtonNodeArray.forEach(element => {
                if (element.Id == ActiveNodeId) {
                    //Привязка ctrlz
                    let CtrlZElem = new HistoryElem();
                    CtrlZElem.Element = {};
                    CtrlZCheckLimit();
                    CtrlZElem.Type = "BUTTON_NODE_MODIFIED";
                    Object.assign(CtrlZElem.Element, element);
                    CtrlZArray.push(CtrlZElem);

                    element.Url = $('#node_info_url').val();
                    element.Title = $('#node_info_urltitle').val();
                    $(`#${ActiveNodeId} .node_title_link`).text(element.Title);
                    $(`#${ActiveNodeId} input`).val(element.Url);
                }
            });

            break;

        default:

            break;
    }

}

export function DeleteActiveNodes() {
    $('.active_node').each(function (key, value) {
        key = 0;
        var id = $(value).attr('id');
        if (id === 'node_sc_0') return;
        if ($(value).hasClass('node_link')) {
            ActiveNodeType = 'url_node';
        } else {
            ActiveNodeType = 'scene_node';
        }

        ActiveNodeId = id;
        DeleteNode();
    });
    ActiveNodeId = 0;
}

export function ClearActiveNodes() {
    $('.active_node').removeClass('active_node');
}

export function DeleteNode() {
    switch (ActiveNodeType) {
        case "scene_node":
            if (ActiveNodeId == "node_sc_0") {
                alert("Нельзя удалить стартовую сцену")
                return;
            }
            NodeArray.forEach(element => {
                if (element.Id == ActiveNodeId) {

                    var buttonNodesArr = [];
                    NodeArray.forEach(element2 => {
                        element2.Childs.forEach(element3 => {
                            if (element3 == ActiveNodeId) {
                                ButtonNodeArray.forEach(element4 => {
                                    if (element4.Id == `jump_node_${element2.Id}_${ActiveNodeId}`) {
                                        buttonNodesArr.push(element4);

                                    }
                                });
                            }
                        });
                    });

                    //Привязка ctrlz
                    let CtrlZElem = new HistoryElem();
                    CtrlZCheckLimit();
                    CtrlZElem.Type = "NODE_DELETED";
                    element.Position.X = $(`#${element.Id}`).offset().left;
                    element.Position.Y = $(`#${element.Id}`).offset().top;
                    CtrlZElem.Element = {
                        Node: element,
                        buttonNodes: buttonNodesArr
                    };
                    CtrlZArray.push(CtrlZElem);
                    element.ChildsUrl.forEach(element2 => {
                        arrowsDrawer.remove(ActiveNodeId, element2);
                    });
                    element.Childs.forEach(element2 => {
                        arrowsDrawer.remove(`#${ActiveNodeId}`, `#${element2}`);
                        ButtonNodeArray.forEach(element3 => {
                            if (element3.FromId == element2.Id || element3.FromId == ActiveNodeId) {
                                ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                                    return f !== element3
                                })
                            }

                        });
                    });
                    NodeArray = NodeArray.filter(function (f) {
                        return f !== element
                    })
                }
            });
            NodeArray.forEach(element => {
                element.Childs.forEach(element2 => {
                    if (element2 == ActiveNodeId) {
                        arrowsDrawer.remove(`#${element.Id}`, `#${ActiveNodeId}`);
                        ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                            return f.Id !== `jump_node_${element.Id}_${ActiveNodeId}`
                        })
                        element.Childs = element.Childs.filter(function (f) {
                            return f !== ActiveNodeId
                        })
                    }
                });
            });
            RemovedNodes.push(ActiveNodeId);


            break;
        case "jump_node":
            ButtonNodeArray.forEach(element => {
                if (element.Id == ActiveNodeId) {

                    arrowsDrawer.remove(`#${element.FromId}`, `#${element.Id}`);
                    arrowsDrawer.remove(`#${element.Id}`, `#${element.ToId}`);

                    NodeArray.forEach(element2 => {
                        if (element2.Id == element.FromId) {
                            element2.Childs = element2.Childs.filter(function (f) {
                                return f !== element.ToId
                            })
                        }
                    });
                    ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                        return f !== element
                    })
                    //Привязка ctrlz
                    let CtrlZElem = new HistoryElem();
                    CtrlZCheckLimit();
                    CtrlZElem.Type = "BUTTON_NODE_DELETED";
                    element.Position.X = $(`#${element.Id}`).offset().left;
                    element.Position.Y = $(`#${element.Id}`).offset().top;
                    let Entity = {
                        Node: element,
                        IsUrl: false
                    }
                    CtrlZElem.Element = Entity;
                    CtrlZArray.push(CtrlZElem);
                }
            });

            break;
        case "url_node":
            ButtonNodeArray.forEach(element => {
                if (element.Id == ActiveNodeId) {
                    let parentsId = [];
                    NodeArray.forEach(element2 => {
                        element2.ChildsUrl.forEach(element3 => {
                            if (element3 == element.Id)
                                parentsId.push(element2.Id);
                        });
                        element2.ChildsUrl = element2.ChildsUrl.filter(function (f) {
                            return f !== ActiveNodeId
                        })
                        arrowsDrawer.remove(`#${element2.Id}`, `#${ActiveNodeId}`);
                    });


                    ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                        return f !== element
                    })

                    //Привязка ctrlz
                    let CtrlZElem = new HistoryElem();
                    CtrlZCheckLimit();
                    CtrlZElem.Type = "BUTTON_NODE_DELETED";
                    element.Position.X = $(`#${element.Id}`).offset().left;
                    element.Position.Y = $(`#${element.Id}`).offset().top;
                    let Entity = {
                        Node: element,
                        ParentsId: parentsId,
                        IsUrl: true
                    }
                    CtrlZElem.Element = Entity;
                    CtrlZArray.push(CtrlZElem);
                }
            });

            break;

        default:

            break;
    }
    $(`#${ActiveNodeId}`).remove();
    arrowsDrawer.redraw();
}

export function ChangeText(id, IsJump) {
    if (IsJump == true) {
        let urln = $(`#${id}`).val();
        $('#node_info_url').val(urln);
    } else {
        let urln = $(`#${id}`).val();
        $('#node_info_jumptitle').val(urln);
    }


}

export function AddRelative(id, IsJump) {
    if (FirstRelative) {
        FirstRelative = false;
        FirstRelativeId = id
        $(`.node_icon_relativ_link`).show();

    } else {
        if (FirstRelativeId != id) {
            if (IsJump) {
                let deleted = false;
                NodeArray.forEach(element => {

                    if (element.Id == FirstRelativeId) {
                        for (let index = 0; index < element.ChildsUrl.length; index++) {

                            if (element.ChildsUrl[index] == id) {
                                element.ChildsUrl.splice(index, 1);
                                arrowsDrawer.remove(`#${FirstRelativeId}`, `#${id}`);
                                //Привязка ctrlz
                                let CtrlZElem = new HistoryElem();
                                CtrlZCheckLimit();
                                CtrlZElem.Type = "REMOVE_RELATIVE";
                                let Entity = {
                                    FromId: FirstRelativeId,
                                    ToId: id,
                                    IsUrl: true
                                }
                                CtrlZElem.Element = Entity;
                                CtrlZArray.push(CtrlZElem);
                                arrowsDrawer.redraw();
                                FirstRelative = true;
                                $(`.node_icon_relativ_link`).hide();
                                deleted = true;
                            }
                        }


                        if (deleted == false) {
                            element.ChildsUrl.push(id);
                            arrowsDrawer.arrow(`#${FirstRelativeId}`, `#${id}`);
                            //Привязка ctrlz
                            let CtrlZElem = new HistoryElem();
                            CtrlZCheckLimit();
                            CtrlZElem.Type = "ADD_RELATIVE";
                            let Entity = {
                                FromId: FirstRelativeId,
                                ToId: id,
                                IsUrl: true
                            }
                            CtrlZElem.Element = Entity;
                            CtrlZArray.push(CtrlZElem);
                            arrowsDrawer.redraw();
                            FirstRelative = true;

                            $(`.node_icon_relativ_link`).hide();
                            $(`.node_icon_relativ_sc`).show();
                        }
                    }
                });
            } else {
                let deleted = false;

                NodeArray.forEach(element => {
                    if (element.Id == FirstRelativeId) {
                        for (let i = 0; i < element.Childs.length; i++) {

                            if (element.Childs[i] == id) {
                                element.Childs.splice(i, 1);

                                arrowsDrawer.remove(`#${FirstRelativeId}`, `#${id}`);
                                let nodeButton = GetButtonNodeByIds2(FirstRelativeId, id, false);
                                ButtonNodeArray = ButtonNodeArray.filter(function (f) {
                                    return f.Id !== `jump_node_${FirstRelativeId}_${id}`
                                })
                                //Привязка ctrlz
                                let CtrlZElem = new HistoryElem();
                                CtrlZCheckLimit();
                                CtrlZElem.Type = "REMOVE_RELATIVE";
                                let Entity = {
                                    buttonNode: nodeButton,
                                    FromId: FirstRelativeId,
                                    ToId: id,
                                    IsUrl: false
                                }
                                CtrlZElem.Element = Entity;
                                CtrlZArray.push(CtrlZElem);
                                arrowsDrawer.redraw();
                                FirstRelative = true;
                                $(`.node_icon_relativ_link`).hide();
                                deleted = true;
                            }
                        }

                        if (deleted == false) {

                            ButtonNodeArray.push(new ButtonNode());

                            ButtonNodeArray[ButtonNodeArray.length - 1].Id = `jump_node_${FirstRelativeId}_${id}`;
                            ButtonNodeArray[ButtonNodeArray.length - 1].Title = `Переход на сцену`;
                            ButtonNodeArray[ButtonNodeArray.length - 1].IsUrl = false;
                            ButtonNodeArray[ButtonNodeArray.length - 1].FromId = FirstRelativeId;
                            ButtonNodeArray[ButtonNodeArray.length - 1].ToId = id;

                            ButtonNodeArray[ButtonNodeArray.length - 1].Position = {
                                X: 100, //+ (newnodepadding * (NodeArray.length + ButtonNodeArray.length)),
                                Y: 100
                            }

                            //Привязка ctrlz
                            let CtrlZElem = new HistoryElem();
                            CtrlZCheckLimit();
                            CtrlZElem.Type = "ADD_RELATIVE";
                            let Entity = {
                                Id: ButtonNodeArray[ButtonNodeArray.length - 1].Id,
                                FromId: FirstRelativeId,
                                ToId: id,
                                IsUrl: false
                            }

                            CtrlZElem.Element = Entity;
                            CtrlZArray.push(CtrlZElem);
                            element.Childs.push(id);

                            arrowsDrawer.arrow(`#${FirstRelativeId}`, `#${id}`);
                            arrowsDrawer.redraw();
                            FirstRelative = true;
                            $(`.node_icon_relativ_link`).hide();
                            $(`.node_icon_relativ_sc`).show();

                        }
                    }
                });


            }
        }


    }

}

function ClearGlobalState() {
    RemovedNodes = [];
    RemovedButtonNodes = [];
    ButtonNodeArray = [];
    NodeArray = [];
    FirstRelative = true;
}

export function Initialize() {
    arrowsDrawer = new $cArrows('#workzone', options);
    $('#fullscreen_toggler').click(function () {
        if (document.fullscreenElement) {
            document.exitFullscreen();
        } else {
            document.documentElement.requestFullscreen();
        }
    });
}

export function InitOnClick() {
    $('.video_elem').on('click', AddNode);
}

export function RedrawArrows() {
    arrowsDrawer.redraw();
}

export function AddNode() {
    ClearActiveNodes();
    if (changeIntroActive) {
        changeIntroActive = false;
        NodeArray.forEach(element => {
            if (element.Id == ActiveNodeId) {
                element.Video = $(this).data("videourl");
                element.Preview = $(this).data("videopreview");
                element.Name = $(this).data('name');
                element.Buttons_Time = parseFloat($(this).data('duration')) - parseFloat($(".library").data('default-buttons-time'));
                element.Buttons_Time = Number(NodeArray[NodeArray.length - 1].Buttons_Time.toFixed(2));
                element.Buttons_Time = (NodeArray[NodeArray.length - 1].Buttons_Time < 0) ? 0 : NodeArray[NodeArray.length - 1].Buttons_Time;
                element.Tail_Time = element.Buttons_Time;
                $(`#${ActiveNodeId}`).children(".node_title_sc").text(element.Name);
                $(`#${ActiveNodeId}`).children(".node_title_sc").attr("title", element.Name);
                $(`#${ActiveNodeId}`).children(".relativ_sc").children(".node_background_sc").attr("src", element.Preview);
                $(`#${ActiveNodeId}`).removeClass("node_disabled");
                SetModalOff();
            }
        });
    } else {
        NodeArray.push(new Node());

        var curid;
        if (RemovedNodes.length == 0) {
            curid = `node_sc_${NodeArray.length - 1}`;
        } else {
            curid = RemovedNodes.shift();
            RemovedNodes = RemovedNodes.filter(function (f) {
                return f !== curid
            });
        }

        if (NodeArray.length == 0) {
            NodeArray[NodeArray.length - 1].Id = "intro";
        } else {
            NodeArray[NodeArray.length - 1].Id = curid;
        }
        NodeArray[NodeArray.length - 1].Video = $(this).data("videourl");
        NodeArray[NodeArray.length - 1].Preview = $(this).data("videopreview");
        NodeArray[NodeArray.length - 1].Name = $(this).data('name');
        NodeArray[NodeArray.length - 1].Length = $(this).data('duration');
        NodeArray[NodeArray.length - 1].Buttons_Time = parseFloat($(this).data('duration')) - parseFloat($(".library").data('default-buttons-time'));
        NodeArray[NodeArray.length - 1].Buttons_Time = Number(NodeArray[NodeArray.length - 1].Buttons_Time.toFixed(2));
        NodeArray[NodeArray.length - 1].Buttons_Time = (NodeArray[NodeArray.length - 1].Buttons_Time < 0) ? 0 : NodeArray[NodeArray.length - 1].Buttons_Time;
        NodeArray[NodeArray.length - 1].Tail_Time = NodeArray[NodeArray.length - 1].Buttons_Time;
        NodeArray[NodeArray.length - 1].Position = {
            X: 100, //+ (newnodepadding * (NodeArray.length + ButtonNodeArray.length)),
            Y: 100
        }
        //addnode
        let nodeId = `${(NodeArray.length == 0) ? "intro" : "node_sc_" + curid.substring(8)}`;
        $("#workzone").append(`<div class="node_sc" style="transform: scale(${scale})" id="${nodeId}">
    <div class="node_settings" id="node_settings_${curid.substring(8)}"><span class="node_settings_elem" OnClick='EditNode("node_sc_${curid.substring(8)}","scene_node"),ModalOn(),ShowSettings("${curid.substring(8)}")')'>Настроить сцену</span><span class="node_settings_elem" onclick="DeleteNode()">Удалить сцену</span><span class="node_settings_elem" onclick="ChangeIntro()">Заменить видео</span></div>
    <span class="node_title_sc" title="${$(this).data('name')}">${$(this).data('name')}</span>
    <span class="node_edit_sc" OnClick='ShowSettings("${curid.substring(8)}"), EditNode("node_sc_${curid.substring(8)}","scene_node")'>...</span>
    <span class="relativ_sc"><img class="node_background_sc" src="${$(this).data("videopreview")}" alt="+"></span>
    <span class="node_icon_relativ_sc" OnClick='AddRelative("node_sc_${curid.substring(8)}", false)'><img class="node_relativ_icon_sc" src="/static/img/icon-plus.png" alt="+"></span>
    </div>`);
        $(".node_sc").draggable(draggableBindings);
        $(".node_sc").mouseup(function () {
            var clickedID = $(this).attr('id');
            NodeArray.forEach(NodeArrayElem => {
                if (NodeArrayElem.Id == clickedID) {
                    NodeArrayElem.Position.X = $(this).offset().left;
                    NodeArrayElem.Position.Y = $(this).offset().top;
                }
            });

        });
        $(`#${nodeId}`).css({left: $('#workzone').scrollLeft() + 100, top: $('#workzone').scrollTop() + 100});


        //Привязка ctrlz
        let CtrlZElem = new HistoryElem();
        CtrlZCheckLimit();
        CtrlZElem.Type = "NODE_CREATED";
        CtrlZElem.Element = NodeArray[NodeArray.length - 1].Id;
        CtrlZArray.push(CtrlZElem);
        var NodeActive = $(`#${curid}`);
        NodeActive.click(function (event) {
            if (event.shiftKey) {
                MultiplySelect($(event.target).closest('.node_sc').attr('id'));
            }
        });
    }
}

export function SetModalOff() {
    if (SetModalOpen) {
        let SetList = document.getElementsByClassName('node_settings');
        for (let i = 0; i < SetList.length; i++) {
            SetList[i].style.display = 'none';
            SetModalOpen = false;
        }
    }
}

export function ShowSettings(id) {
    let Modal = document.getElementById('node_settings_' + id);
    let SetList = document.getElementsByClassName('node_settings');

    for (let i = 0; i < SetList.length; i++) {
        if (SetList[i] != Modal) {
            SetList[i].style.display = 'none';
            SetModalOpen = false;
        }
    }
    if (Modal.style.display == 'grid') {
        Modal.style.display = 'none';
        SetModalOpen = false;
    } else {
        Modal.style.display = 'grid';
        SetModalOpen = true;

    }
}

export function ModalOn() {
    let Modal = document.getElementById('form_modal');
    if (Modal.style.display == 'grid') {
        Modal.style.display = 'none';

    } else {
        Modal.style.display = 'grid';

    }

}

export function AddButtonNode() {
    ButtonNodeArray.push(new ButtonNode());
    var curid;
    if (RemovedButtonNodes.length == 0) {
        curid = `jump_node_${ButtonNodeArray.length - 1}`;
    } else {
        curid = RemovedButtonNodes.shift();
        RemovedButtonNodes = RemovedButtonNodes.filter(function (f) {
            return f !== curid
        });
    }
    ButtonNodeArray[ButtonNodeArray.length - 1].Id = `jump_node_${curid.substring(10)}`;
    ButtonNodeArray[ButtonNodeArray.length - 1].Title = `Переход на ссылку`;
    ButtonNodeArray[ButtonNodeArray.length - 1].IsUrl = true;
    ButtonNodeArray[ButtonNodeArray.length - 1].Position = {
        X: 100, //+ (newnodepadding * (NodeArray.length + ButtonNodeArray.length)),
        Y: 100
    }
    //<input type="text" OnInput='ChangeText()' placeholder="URL" class="node_input_link whtt">
    $("#workzone").append(`<div class="node_link" style="transform: scale(${scale})" id="jump_node_${curid.substring(10)}">
    <div class="node_settings" id="node_settings_j${curid.substring(10)}"><span class="node_settings_elem" OnClick='ModalOn(),ShowSettings("j${curid.substring(10)}")')'>Настроить переход</span><span class="node_settings_elem" onclick="DeleteNode()">Удалить переход</span></div>
    <span class="node_title_link">Переход на ссылку</span>
    <span class="node_edit_link" OnClick='ShowSettings("j${curid.substring(10)}"),EditNode("jump_node_${curid.substring(10)}", "url_node")'>...</span>
    <input type="text" OnInput='EditNode("jump_node_${curid.substring(10)}", "url_node"),ChangeText("jump_input_${curid.substring(10)}",true),SaveChanges()' placeholder="Название ссылки" class="node_input_link blle" id="jump_input_${curid.substring(10)}">
    
    <span class="node_icon_relativ_link" OnClick='AddRelative("jump_node_${curid.substring(10)}", true)'><img class="node_relativ_icon_link" src="/static/img/icon-plus.png" alt="+"></span>
</div>`);
    $(".node_link").draggable(draggableBindings);
    $(`.node_icon_relativ_link`).hide();

    //Привязка ctrlz
    let CtrlZElem = new HistoryElem();
    CtrlZCheckLimit();
    CtrlZElem.Type = "BUTTON_NODE_CREATED";
    CtrlZElem.Element = ButtonNodeArray[ButtonNodeArray.length - 1].Id;
    CtrlZArray.push(CtrlZElem);

}

export function MultiplySelect(id) {
    $(`#${id}`).toggleClass("active_node");
    $(".active_node").multiDraggable({
        group: ".active_node",
        dragNative: () => {
        }
    });

}

export function EditNode(id, type) {
    ActiveNodeId = id;
    ActiveNodeType = type;

    $("#buttonsarray").empty();

    $(".node_link").removeClass("active_node");
    $(".node").removeClass("active_node");
    $(".node_sc").removeClass("active_node");
    $(`#${id}`).addClass("active_node");
    $(".btn_save_node").show();
    switch (type) {
        case "scene_node":
            $(".input_node_url").hide();
            $(".input_node_jump").hide();
            $(".input_node_scene").show();

            NodeArray.forEach(element => {
                if (element.Id == id) {

                    element.Childs.forEach(element2 => {
                        let buttonNode = GetButtonNodeByIds(element.Id, element2);
                        let FromNode = GetNodeById(element2);
                        $("#buttonsarray").append(`<div class="button_rel">
                        <span class="button_title">Переход на сцену - ${FromNode.Name}</span>
                        <input type="text" id="rel_id_${element2}" placeholder="Заголовок кнопки" value="${buttonNode.Title}">
                        </div>`);
                    });
                    $('#node_lenght').text(element.Length);
                    $('#node_info_name').val(element.Name);
                    $('#node_info_tailtime').val(element.Tail_Time);
                    $('#node_info_buttonstime').val(element.Buttons_Time);
                }
            });

            break;
        case "jump_node":
            $(".input_node_url").hide();
            $(".input_node_jump").show();
            $(".input_node_scene").hide();

            ButtonNodeArray.forEach(element => {
                if (element.Id == id) {
                    $('#node_info_jumptitle').val(element.Title);
                }
            });

            break;
        case "url_node":
            $(".input_node_url").show();
            $(".input_node_jump").hide();
            $(".input_node_scene").hide();

            ButtonNodeArray.forEach(element => {
                if (element.Id == id) {
                    $('#node_info_url').val(element.Url);
                    $('#node_info_urltitle').val(element.Title);
                }
            });

            break;

        default:

            break;
    }
}
