import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import LoginView from '@/views/LoginView.vue';
import VideosView from '@/views/VideosView.vue';
import SceneEditView from '@/views/SceneEdit.vue';
import { store } from '@/store';


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {requiresAuth: true}
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },
  {
    path: '/videos',
    name: 'videos',
    component: VideosView,
    meta: {requiresAuth: true}
  },
  {
    path: '/scene/:id',
    name: 'scene_edit',
    component: SceneEditView,
    meta: {requiresAuth: true}
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router
