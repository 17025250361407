<template>
  <ScenariesGrid />
</template>

<script>
// @ is an alias to /src
import ScenariesGrid from '@/components/ScenariesGrid.vue'

export default {
  name: 'HomeView',
  components: {
    ScenariesGrid
  }
}
</script>
