<template>
      <link href="/static/css/editstyle.css" rel="stylesheet"/>
  <div v-if="!isMobile()">
    <div class="container1">
      <div ref="workzone" class="workzone" id="workzone">
      </div>
      <div class="left">
        <FoldersDropdown v-model="currentCategory" />
      <div class="library" :data-default-buttons-time="defaultButtonsTime">
        <div v-for="video in videos" :key="video.id" class="video_elem"
             :data-name="video.name" :data-videourl="video.video_url" :data-videopreview="video.preview_url" :data-duration="video.length">
          <img :src="video.preview_url" alt="" class="video_preview">
          <span class="video_title">{{ video.name }}</span>
        </div>
      </div>
      <b-pagination
        v-if="videosCount > 0"
        v-model="currentPage"
        :total-rows="videosCount"
        :per-page="10"
        :limit="3"
        aria-controls="videos-table-controls"
        style="justify-content: center !important;"
      ></b-pagination>
      </div>
    </div>
    <a id='linkForSavingFile' style='display:none'></a>
    <div class="top_btn_control">
      <i class="fas fa-expand fullscreenstyle" id="fullscreen_toggler"></i>
      <a @click="insertData" target="_blank" :href="`https://video-quest.ru/player2?scene_id=${sceneId}`">
        <i class="fas fa-play play_cs" aria-hidden="true"></i>
      </a>
    </div>
    <div class="btn_control">
      <span class="btn_save_node" @click="insertData">Сохранить</span>
      <span class="btn_add_node" @click="addBtnNode">Добавить ссылку  <img class="svg_plus" src="/static/img/icon-plus.png"
                                                                            alt="+"></span>
    </div>
    <div class="blck" id="form_modal" ref="nodeModal">

    <div class="node_form" >
      <div class="input_node_info input_node_scene"><span>Наименование сцены</span><input type="text" class="input_info"
                                                                                id="node_info_name" placeholder="Наименование сцены">
      </div>
      <div class="input_node_info input_node_scene"><span>Tail-time</span><input type="number" class="input_info"
                                                                                id="node_info_tailtime"
                                                                                placeholder="Tail-time"></div>
      <div class="input_node_info input_node_scene"><span>Время появления действий</span><span>Длительность видео:<span id="node_lenght">00</span></span><input type="number"
                                                                                              class="input_info"
                                                                                              id="node_info_buttonstime"
                                                                                              placeholder="Время появления действий">
      </div>
      <div class="input_node_info input_node_url"><span>Введите адрес страницы</span><input type="text" class="input_info" id="node_info_url"
                                                                         placeholder="URL"></div>
      <div class="input_node_info input_node_url"><span>Заголовок кнопки</span><input type="text" class="input_info"
                                                                                id="node_info_urltitle"
                                                                                placeholder="Заголовок кнопки"></div>
      <div class="input_node_info input_node_jump"><span>Заголовок кнопки</span><input type="text" class="input_info"
                                                                                 id="node_info_jumptitle"
                                                                                 placeholder="Заголовок кнопки"></div>
      <div class="btnconb"><span class="btn_save_node" @click="saveChanges">Сохранить изменения</span>
      <span class="btn_add_node btn_closed" @click="ModalO">Закрыть</span></div>
    </div>
    <div class="buttonsarray" id="buttonsarray">

    </div>
    </div>
  </div>
  <div v-else>
    <h1>Нельзя редактировать сценарий на мобильном устройстве.</h1>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import axios from "axios";
import {
  SaveChanges,
  SaveSchema,
  LoadSchema,
  AddButtonNode,
  AddRelative,
  AddNode,
  FindButtonNode,
  DeleteNode,
  ChangeText,
  ShowSettings,
  ModalOn,
  SetModalOff,
  TweakFullscreen,
  EditNode,
  ChangeIntro,
  Initialize,
  CtrlZMethod,
  GetLog,
  InitOnClick,
  MultiplySelect,
  ClearActiveNodes,
  DeleteActiveNodes,
  RedrawArrows,
    SetScale
} from '@/assets/js/classes.js';
import $ from 'jquery';
import FoldersDropdown from "@/components/FoldersDropdown.vue";


export default {
  name: "SceneEditView",
  components: {FoldersDropdown},
  computed: {
    ...mapGetters({videos: 'videosList', videosCount: 'videosCount', categories: 'videoCategories'}),
  },
  watch: {
    currentPage(n) {
      this.loadVideos({page: n, count: 10, sort_dir: this.sortDir, sort_by: this.sortBy, category: this.currentCategory}).then(InitOnClick);
    },
    currentCategory(n) {
      this.currentPage = 1;
      this.loadVideos({page: this.currentPage, count: 10, sort_dir: this.sortDir, sort_by: this.sortBy, category: n}).then(InitOnClick);
      this.loadVideosCount(n);
    },
    scale(n) {
      this.scaleScene(n);
    }
  },
  data() {
    return {
      currentPage: 1,
      defaultButtonsTime: 0,
      sortDir: 'ascending',
      sortBy: 'name',
      scale: 1.0,
      currentCategory: null,
      sceneId: null
    }
  },
  methods: {
    ...mapActions({loadVideos: 'loadVideos', loadVideosCount: 'loadVideosCount'}),
    saveChanges() {
      SaveChanges();
      this.$refs.nodeModal.style.display = 'none';
    },
    scaleScene(n) {
      SetScale(n);
      let nodes = $('.node_sc, .node_link');
      nodes.each((array, element) => {
        let el = $(element);
        if (!el.data('left')) {
          el.data('left', el.css('left'));
          el.data('top', el.css('top'));
        }

        el.css('left', `${parseFloat(el.data('left')) * n}px`);
        el.css('top', `${parseFloat(el.data('top')) * n}px`);
      })
      if (n !== 1.0)
        nodes.css('transform', `scale(${n})`);
      else
        nodes.css('transform', 'none');
      RedrawArrows();
    },
    async insertData() {
      await axios.post('/scenes/insert-data', SaveSchema(), {params: {scene_id: this.$route.params.id}});
      //$("#workzone").scrollTop = 0;
      //$("#workzone").scrollLeft = 0;
      //if (response.data.success) LoadSchema(response.data.data);
      this.scaleScene(this.scale);
      alert('Сцена сохранена');
    },
     isMobile() {
       return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
     },
    async loadSchemeData() {
      let resp = await axios.get('/scenes/data', {params: {scene_id: this.$route.params.id, raw: true}});
      let buttonsTimeResponse = await axios.get('/scenes/buttons-time', {params: {scene_id: this.$route.params.id}});
      this.defaultButtonsTime = buttonsTimeResponse.data.time;
      LoadSchema(resp.data);
    },
    addBtnNode() {
      AddButtonNode();
    },
    ModalO() {
      ModalOn();
    },
    addWindowBinds() {
        window.AddRelative = AddRelative;
        window.AddNode = AddNode;
        window.FindButtonNode = FindButtonNode;
        window.DeleteNode = DeleteNode;
        window.ChangeIntro = ChangeIntro;
        window.ChangeText = ChangeText;
        window.ShowSettings = ShowSettings;
        window.ModalOn = ModalOn;
        window.EditNode = EditNode;
        window.SaveChanges = SaveChanges;
        window.CtrlZMethod = CtrlZMethod;
        window.GetLog = GetLog;
        window.ClearActiveNodes = ClearActiveNodes;
        window.DeleteActiveNodes = DeleteActiveNodes;
        window.MultiplySelect = MultiplySelect;
        window.TweakFullscreen = TweakFullscreen;
    },
    keyupHandler(event) {
      if (event.code === 'KeyZ' && (event.ctrlKey)) {
        CtrlZMethod();
      } else if (event.code === 'Delete') {
        DeleteActiveNodes();
      }
    }
  },
  async unmounted() {
    window.removeEventListener('keyup', this.keyupHandler);
  },
  async beforeUnmount() {
    await axios.post('/scenes/insert-data', SaveSchema(), {params: {scene_id: this.sceneId}});
  },
  async mounted() {
    this.sceneId = this.$route.params.id;
    this.addWindowBinds();
    await this.loadVideosCount();
    window.addEventListener('keyup', this.keyupHandler);
    Initialize();
    await this.loadVideos({page: this.currentPage, count: 10, sort_dir: this.sortDir, sort_by: this.sortBy});
    InitOnClick();
    await this.loadSchemeData();
      var scr = $("#workzone");
      var scrModal = $("#form_modal");
      scrModal.mousedown(function (event) {
         if (event.target.id.toLowerCase() === 'form_modal') {
            ModalOn();
         }         
      });
      scr.mousedown(function (event) {
        if (event.target.tagName.toLowerCase() === 'canvas') {
          SetModalOff();
          var startX = this.scrollLeft + event.pageX;
          var startY = this.scrollTop + event.pageY;
          scr.mousemove(function (event) {
            this.scrollLeft = startX - event.pageX;
            this.scrollTop = startY - event.pageY;
            return false;
          });
        }
      });
      $(window).mouseup(function () {
        scr.off("mousemove");
      }); 
      
      var NodeActive = $(".node_sc");
      NodeActive.click(function (event) {
         if (event.shiftKey) {
           MultiplySelect($(event.target).closest('.node_sc').attr('id'));
         }
      });
      var RemActive = $("#workzone");
      RemActive.dblclick(function () {
         ClearActiveNodes();
      });
       
      window.addEventListener('wheel', (event) => {
        let isWheelUp = event.deltaY > 0;
        if (event.ctrlKey) {
          event.preventDefault();
          if (!isWheelUp) this.scale += this.scale < 1.0 ? 0.05 : 0;
          else this.scale -= this.scale > 0.5 ? 0.05 : 0;
        }
      }, {passive: false});
    this.scaleScene(1.0);
  }
}
</script>

<style scoped>


</style>
