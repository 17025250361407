import axios from 'axios';

const state = {
    username: null
}

const getters = {
    isAuthenticated: state => !!state.username,
    activeUser: state => state.username,
}

const actions = {
    async login({ commit }, data) {
        let resp = await axios.post('/admin/login', data);
        if (resp.data.success) commit('setUser', data.username);
    },
    async logout({ commit }) {
        await axios.post('/admin/logout');
        commit('setUser', null);
    }
}

const mutations = {
    setUser(state, username) {
        state.username = username;
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}