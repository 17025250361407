import axios from 'axios';

const state = {
    videos: [],
    videosCount: 0,
    videoCategories: []
}

const getters = {
    videosList: state => state.videos,
    videosCount: state => state.videosCount,
    videoCategories: state => state.videoCategories
}

const actions = {
    async loadVideos({ commit }, payload) {
        let resp = await axios.get(`/videos/list`, { params: payload });
        commit('setVideos', resp.data);
    },
    async deleteVideo({ commit }, videoId) {
        let resp = await axios.delete(`/videos/remove/${videoId}`);
        if (resp.data.success) commit('removeVideo', videoId);
    },
    async uploadVideo({ commit }, {chunk, category, fileData}) {
        let formData = new FormData();
        formData.append("file_size", fileData.size);
        formData.append("chunk_offset", fileData.chunkOffset);
        formData.append("filename", fileData.filename);
        formData.append("chunks_count", fileData.chunksCount);
        if (category) {
            formData.append("category", category);
            commit('addCategory', category);
        }
        formData.append("attached_file", chunk);
        let response = await axios.post('/videos/upload', formData);
        if (response.data.success) {
            if (response.data.progress !== 100)
                commit('uploadVideoProgress', { progress: response.data.progress, totalSize: fileData.size, filename: fileData.filename });
            else
                commit('uploadVideoFinish', { serverData: response.data.data, filename: fileData.filename });
        } else{
            commit('uploadVideoFailed', fileData.filename);
            alert(`Нельзя загрузить файл ${fileData.filename} по причине "${response.data.message}"`);
        }
    },
    async loadVideosCount({ commit }, category = null) {
        let resp = await axios.get('/videos/count', { params: { category: category } });
        commit('setVideosCount', resp.data);
    },
    async editVideo({ commit }, {videoId, newName, newPreview, newCategory}) {
        let formData = new FormData();
        formData.append("name", newName);
        if (newPreview !== null)
            formData.append("preview", newPreview);
        if (newCategory) {
            formData.append("category", newCategory);
            commit('addCategory', newCategory);
        }
        let resp = await axios.post(`/videos/edit/${videoId}`, formData);
        if (resp.data.success) commit('videoEdit', { videoId, newName, newPreview: newPreview ? resp.data.preview_url : null, newCategory: newCategory });
    }
}

const mutations = {
    removeVideo(state, videoId) {
        state.videos.forEach(function (value, index) {
            if (value.id === videoId) {
                state.videos.splice(index, 1);
                state.videosCount--;
            }
        })
    },
    uploadVideoProgress(state, { progress, totalSize, filename }) {
        const videoObj = {
            id: null,
            name: filename,
            preview_url: null,
            video_url: null,
            isUploading: true,
            progress: progress,
            size: totalSize,
            scenes_used: []
        };
        let isFound = false;
        state.videos.forEach(function (value, index) {
            if (value.name === filename) {
                state.videos[index] = videoObj;
                isFound = true;
            }
        })
        if (!isFound) state.videos.push(videoObj);
    },
    uploadVideoFinish(state, {serverData, filename}) {
        state.videos.forEach(function (value, index) {
            if (value.name === filename) {
                state.videos[index] = serverData;
                state.videosCount++;
            }
        })
    },
    uploadVideoFailed(state, filename) {
        state.videos.forEach(function (value, index) {
            if (value.name === filename) {
                state.videos.splice(index, 1);
            }
        })

    },
    setVideos(state, videosArray) {
        state.videos = videosArray;
    },
    setVideosCount(state, countData){
        state.videosCount = countData.count;
        state.videoCategories = countData.categories;
    },
    videoEdit(state, { videoId, newName, newPreview, newCategory }) {
        state.videos.forEach(function (value, index) {
            if (value.id === videoId) {
                state.videos[index].name = newName;
                if (newPreview !== null)
                    state.videos[index].preview_url = newPreview;
                if (newCategory !== null)
                    state.videos[index].category = newCategory;
            }
        })
    },
    addCategory(state, category) {
        if (!state.videoCategories.includes(category)) state.videoCategories.push(category);
    }
}

export default {
      state,
      getters,
      actions,
      mutations
}